import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ShortLivedTokenService {
  private http = inject(HttpClient);

  async get(shortLivedToken: string) {
    return lastValueFrom(this.http.get<string>(`${environment.API_UNAUTH_URL}/short-lived-token/${shortLivedToken}`));
  }
}
